import React from 'react'

import Layout from '../../components/Layout'
import NewsRoll2 from "../../components/NewsRoll2";

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <p
                  className="has-text-weight-semibold is-size-4 has-text-centered"
                  style={{
                    marginBottom: `20px`
                  }}
                >
                  メディア掲載
                </p>
                <div className="content">
                  <NewsRoll2 />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
